<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Space Icons">
                    <template v-slot:description>
                        import { HbSpaceIcon } from 'hummingbird-aviary';
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Examples + Code">

            <v-row no-gutters class="px-4 py-2">

                <v-col cols="4" class="ma-0 pa-0 d-flex align-center justify-center">
                    <HbSpaceIcon type="storage" small />
                </v-col>
                <v-col cols="4" class="ma-0 pa-0 d-flex align-center justify-center">
                    <HbSpaceIcon type="storage" />
                </v-col>
                <v-col cols="4" class="ma-0 pa-0 d-flex align-center justify-center">
                    <HbSpaceIcon type="storage" avatar />
                </v-col>

            </v-row>

                <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbSpaceIcon type="storage" small />
&lt;HbSpaceIcon type="storage" />
&lt;HbSpaceIcon type="storage" avatar />
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Possible Space Icons + Types: Entire List So Far" class="mt-4 mb-6">
            <hb-data-table
                :headers="headers"
                :items="spaceTypes"
                class="pa-6"
            >
                <template v-slot:item.small="{ item }">
                    <HbSpaceIcon :type="item.type" small />
                </template>
                <template v-slot:item.default="{ item }">
                    <HbSpaceIcon :type="item.type" />
                </template>
                <template v-slot:item.avatar="{ item }">
                    <HbSpaceIcon :type="item.type" avatar />
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="Example Effects of Using 'top' Adjustment Prop + Code (just for show, it is misaligned on purpose here just for demo purposes)" class="mt-4 mb-6">

            <v-row no-gutters class="pa-4">

                <HbSpaceIcon type="storage" small top="2px" />
                <HbSpaceIcon type="parking" small top="-5.5px" />
                <HbSpaceIcon type="car_wash" small top="3px" />
                <HbSpaceIcon type="office" small top="6%" />

            </v-row>

                <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbSpaceIcon type="storage" small top="2px" />
&lt;HbSpaceIcon type="parking" small top="-3.5px" />
&lt;HbSpaceIcon type="car_wash" small top="3px" />
&lt;HbSpaceIcon type="office" small top="6%" />
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="'tokens.icons.spaces' from 'tokens.js' file's code (This will be the controller for all the space icons - so that any component can import from this list and be standardized - HbSpaceInfo.vue is importing this list and then looking for the 'type' match and then displaying the correct icon based upon the match. We can add all future new space types and the associated icon in this 'tokens.js' file.)" class="mt-4 mb-6">

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
{{ spaceTypes }}
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

    </div>
</template>
<script type="text/babel">
    import tokens from '../../aviary/tokens.js';

    export default {
        name: "DesignSystemSpaceIcons",
        data: function() {
            return {
                spaceTypes: tokens.icons.spaces,
                headers: [
                    { text: 'type', value: 'type' },
                    { text: 'small', value: 'small' },
                    { text: 'default', value: 'default' },
                    { text: 'avatar', value: 'avatar' },
                ],
                propHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'type', type: 'string', default: 'undefined', description: 'This sets the icon that is displayed. Refer to the design system "tokens.js" file for all possible space types that can be passed into this prop.' },
                    { name: 'small', type: 'boolean', default: 'false', description: 'If changed to true, the small sized icon will appear. This icon size is commonly used in places like <hb-data-table> and <hb-info-card> and other places.' },
                    { name: 'color', type: 'string', default: '#637381', description: 'Manually change the color of the icon.' },
                    { name: 'top', type: 'string', default: 'undefined', description: 'Set a top position adjustment if the icon is not aligning properly to the elements around it. Some examples of what you can place here are "2px", "-2.5px", "5%", etc... (*note: "small" type icons already have default settings for this prop set by the icons.js constant file. You can override these values but you should not have to.)' },
                    { name: 'disable-small-size-position-fix', type: 'boolean', default: 'false', description: 'If changed to true, tells this component to not send the standard position fix to HbIcon for small sized usages. Change this to true if you are seeing icon positioning issues and this may fix it.' },
                    { name: 'disable-top-position-adjustment', type: 'boolean', default: 'false', description: 'If changed to true, overrides/disables the value you place in the "top" prop and instead sets the positioning of the element to a normal "0" position.' },
                    { name: 'disable-position-relative', type: 'boolean', default: 'false', description: 'If changed to true, the default "position:relative" style will be turned off.' },
                    { name: 'avatar', type: 'boolean', default: 'false', description: 'If changed to true. Applies the avatar circle styling around the icon. Only will work for default size.' },
                    { name: 'enable-tooltip', type: 'boolean', default: 'false', description: 'If changed to true, this will enable the tooltip when the icon is hovered over. The tooltip text will be whatever the exact text/string is set by the "type" prop.' },
                ]
            };
        },
    }
</script>

<style scoped>

</style>
